<template>
  <div class="privacy">
    <div class="p1 s1 title">
      Vancheen服务协议
    </div>
    <ul class="ul1">
      <li class="li2">
        <span class="s2"></span><span class="s3">一、服务条款的确认和接纳</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">二、用户同意</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">三、服务条款的修改</span>
      </li>
      <li class="li2">
        <span class="s2"></span
        ><span class="s3">四、用户的账号、密码和安全性</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">五、信息安全</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">六、有限责任</span>
      </li>
      <li class="li2">
        <span class="s2"></span
        ><span class="s3">七、对用户信息的存储和限制</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">八、信息内容的所有权</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">九、隐私保护条款</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">十、适用法律</span>
      </li>
      <li class="li2">
        <span class="s2"></span><span class="s3">十一、免责声明</span>
      </li>
    </ul>
    <p class="p2">
      <span class="s3"
        >感谢您选择Vancheen服务。本条款是由佛山乐毅网络科技有限公司（以下简称为“本公司”）与用户签订的隐私条款。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>一、服务条款的确认和接纳</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >本条款提供基于Vancheen进口车APP和Vancheen商家版APP
        （以下简称为“本APP”）的网络服务。如</span
      ><span class="s4">本APP</span
      ><span class="s3"
        >有所增加，则增加的内容作为本公司提供的网络服务的一部分，仍将适用本协议的规定。本APP的各项内容和服务的所有权归本公司拥有。用户在接受本服务之前，请务必仔细阅读本条款。用户使用服务，或通过完成注册程序，表示用户接受所有服务条款。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >本公司提醒您认真阅读、充分理解本协议各条款，特别是以粗体标注部分。如您不同意接受本服务条款的任意内容，或者无法准确理解相关条款含义的，请不要进行后续操作。如果您对本服务条款有疑问的，请通过本公司客服渠道进行询问，本公司将向您解释条款内容。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>二、用户同意</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >1、通过您的IP地址来收集非个人化的信息，例如您的浏览器性质、操作系统种类、给您提供接入服务的ISP的域名等，以优化在您计算机屏幕上显示的页面。通过收集上述信息，我们亦进行客流量统计，从而改进网站的管理和服务。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、提供及时、详尽及准确的个人资料，个人资料包括：姓名、性别、出生日期、电话等。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >3、如果用户提供的个人资料不准确，本公司有结束服务的权利。</span
      >
    </p>
    <p class="p2"><span class="s3">4、用户在本APP的任何行为必须遵循：</span></p>
    <p class="p2">
      <span class="s3">（1）传输资料时必须符合中国有关法规。</span>
    </p>
    <p class="p2">
      <span class="s3">（2）使用信息服务不作非法用途和不道德行为。</span>
    </p>
    <p class="p2"><span class="s3">（3）不干扰或混乱网络服务。</span></p>
    <p class="p2">
      <span class="s3"
        >（4）遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >（5）本APP将不公开用户的姓名、地址、帐号和电话号码等信息（请阅第九条隐私保护条款）。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>三、服务条款的修改</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >1、本APP有权在必要时修改条款，一旦相关内容发生变动，本公司将会通过网站公告等方式向您告知，修改内容一经公告即生效。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、如果不接受所改动的内容，您可以主动取消自己的用户资格。如果您不取消自己的用户资格，则视为接受服务条款的变动。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>四、用户的账号、密码和安全性</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >1、一旦成功注册成为APP用户，您将可使用手机号码进行登录。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、用户将对接收到的手机短信验证码的安全负全部责任。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >3、用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告本公司。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>五、信息安全</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >1、本APP将对您所提供的资料进行严格的管理及保护，本APP将使用相应的技术，防止您的个人资料丢失、被盗用或遭篡改。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、用户理解并接受：任何通过服务取得的信息资料的可靠性有用性取决于用户自己的判断，用户自己承担所有风险和责任。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>六、有限责任</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >本APP对任何由于使用服务引发的直接、间接、偶然及继起的损害不负责任。这些损害可能来自（包括但不限于）：不正当使用服务，或传送的信息不符合规定等。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >本APP不对用户发布信息的删除或储存失败负责，本公司有判定用户的行为是否符合服务条款的要求和精神的保留权利。如果用户违背了服务条款的规定，有中断对其提供服务的权利。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>七、对用户信息的存储和限制</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >本APP不对用户发布信息的删除或储存失败负责，本公司有判定用户的行为是否符合服务条款的要求和精神的保留权利。如果用户违背了服务条款的规定，有中断对其提供服务的权利。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>八、信息内容的所有权</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >1、本公司的信息内容包括：文字、声音、图片、视频、图表；以及其它信息，所有这些内容受版权、商标、标签和其它财产所有权法律的保护。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、用户只能在授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>九、隐私保护条款</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >1、本APP将严格保守用户的个人隐私，承诺未经过您的同意不将您的个人信息任意披露。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、但在以下情形下，我们将无法再提供您前述保证而披露您的相关信息。这些情形包括但不限于：</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >（1）为了您的交易顺利完成，我们不得不把您的某些信息，如您的姓名、联系电话等提供给相关销售，以便于他们及时与您取得联系，提供服务。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >（2）当您在本APP的行为违反的服务条款，或可能损害他人权益或导致他人遭受损害，只要我们相信披露您的个人资料是为了辨识、联络或采取法律行动所必要的行动时。</span
      >
    </p>
    <p class="p2">
      <span class="s3">（3）法律法规所规定的必须披露或公开的个人信息。</span>
    </p>
    <p class="p2">
      <span class="s3"
        >（4）当司法机关或其它有权机关依法执行公务，要求提供特定个人资料时，我们必须给予必要的配合。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>十、适用法律</b></span>
    </p>
    <p class="p2">
      <span class="s3"
        >1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应依照中华人民共和国法律予以处理，并由被告住所地人民法院管辖。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、如发生服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持约束力。</span
      >
    </p>
    <p class="p1">
      <span class="s1"><b>十一、 免责声明</b></span>
    </p>
    <p class="p2"><span class="s3">下列情况时本APP毋需承担任何责任：</span></p>
    <p class="p2">
      <span class="s3"
        >1、由于您将短信验证码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >2、任何由于黑客政击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被篡改等。</span
      >
    </p>
    <p class="p2">
      <span class="s3"
        >3、由于与本APP链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果。</span
      >
    </p>
  </div>
</template>
<script>
export default {
  name: "Privacy",
  data() {
    return {};
  },
  beforeCreate() {
    document.body.style.backgroundColor = "#ffffff";
  }
};
</script>
<style lang="scss" scoped>
p.p1 {
  margin: 0px 0px 0px 0px;
  line-height: 24px;
  font: 17px "PingFang SC Semibold";
  color: #1b1c33;
  -webkit-text-stroke: #1b1c33;
}

p.p2 {
  margin: 0px 0px 0px 0px;
  line-height: 18px;
  font: 13px "PingFang SC";
  color: #1b1c33;
  -webkit-text-stroke: #1b1c33;
}

li.li2 {
  margin: 0px 0px 0px 0px;
  line-height: 18px;
  font: 13px "PingFang SC";
  color: #1b1c33;
  -webkit-text-stroke: #1b1c33;
}

span.s1 {
  font: 17px "PingFang SC";
  font-kerning: none;
}

span.s2 {
  -webkit-text-stroke: 0px #000000;
}

span.s3 {
  font-kerning: none;
}

span.s4 {
  font-kerning: none;
  -webkit-text-stroke: 0px #000000;
}

ul.ul1 {
  list-style-type: none;
}
.title {
  text-align: center;
  font: 28px "PingFang SC Semibold";
  color: #1b1c33;
  -webkit-text-stroke: #1b1c33;
  font-weight: bold;
  padding: 30px 0;
}
</style>
